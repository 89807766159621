export function parseImages(data: {
    title: string
    imagesUploaded: { dataUrl?: any; file?: File }[]
    imageActive: number
}) {
    const returnData = {
        images: [] as (string | File)[],
        featured: '' as string | File,
    }
    if (data.imagesUploaded.length === 0) return returnData

    data.imagesUploaded.forEach((image, index) => {
        if (index === data.imageActive) {
            returnData.featured = image.file || image.dataUrl
        } else {
            returnData.images.push(image.file || image.dataUrl)
        }
    })

    return returnData
}
